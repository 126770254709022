import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CompanyModulesData, QuartersData } from '~/services/api/anza';
import { getSecondsInHours } from '~/utils/timeConversions';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { ModuleType } from '~/types/modules';

export const vendorApi = createApi({
  reducerPath: 'vendorApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: getSecondsInHours(12),
  tagTypes: [
    'Pipeline',
    'VendorModulesMetrics',
    'VendorActiveProjects',
    'AllVendorsModuleMetrics',
    'AllVendorsActiveProjects',
    'Module',
    'AllModules',
  ],
  endpoints: (builder) => ({
    updateModulePricing: builder.mutation<
      ModuleType,
      {
        uuid: string;
        quarters: QuartersData[];
        adders: Partial<CompanyModulesData>;
      }
    >({
      query: (args) => {
        const { uuid, quarters, adders } = args;
        return {
          url: routes.modules.updateModulePricing(uuid),
          method: 'PATCH',
          body: { quarters, adders },
        };
      },
      transformResponse: (response: { data: ModuleType }) => response.data,
    }),
    confirmModulePricing: builder.mutation<ModuleType, { uuid: string }>({
      query: (args) => {
        const { uuid } = args;
        return {
          url: routes.modules.confirmModulePricing(uuid),
          method: 'POST',
        };
      },
      transformResponse: (response: { data: ModuleType }) => response.data,
    }),
    // 👇 Query: Get All Modules for current vendor
    getModules: builder.query<CompanyModulesData[], undefined>({
      query: () => routes.anza.vendor.modules(),
      keepUnusedDataFor: getSecondsInHours(24),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Module' as const,
                id,
              })),
              { type: 'Module', id: 'MODULE' },
            ]
          : [{ type: 'Module', id: 'MODULE' }],
    }),
    // 👇 Query: Get All Modules for all vendors
    getAllModules: builder.query<CompanyModulesData[], { vendorId: string }>({
      query: ({ vendorId }) => `${routes.anza.vendor.vendorModules(vendorId)}`,
      keepUnusedDataFor: getSecondsInHours(24),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'AllModules' as const,
                id,
              })),
              { type: 'AllModules', id: 'ALL_MODULE' },
            ]
          : [{ type: 'AllModules', id: 'ALL_MODULE' }],
    }),
  }),
});

export const {
  useUpdateModulePricingMutation,
  useConfirmModulePricingMutation,
  useGetModulesQuery,
  useLazyGetModulesQuery,
  useGetAllModulesQuery,
} = vendorApi;
