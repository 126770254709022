import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ActiveProjectsData,
  ActiveProjectsResponse,
  VendorPipelineResponse,
} from '~/services/api/anza';
import { getSecondsInHours } from '~/utils/timeConversions';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';

export const supplierDashboardApi = createApi({
  reducerPath: 'vendorDashboardApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: getSecondsInHours(12),
  tagTypes: [
    'Pipeline',
    'SupplierActiveProjects',
    'AllSupplierActiveProjects',
    'AllModuleMetrics',
    'SupplierDashboardVendorModulesMetrics',
  ],
  endpoints: (builder) => ({
    // 👇 Query: Get Supplier's Pipeline Data
    getDashboardPipeline: builder.query<VendorPipelineResponse, void>({
      query: () => routes.anza.supplierDashboard.pipeline(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [{ type: 'Pipeline', id: 'PIPELINE' }],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
    // 👇 Query: Get Vendor's Module Metrics Data
    getSupplierDashboardVendorModuleMetrics: builder.query<
      VendorPipelineResponse,
      { vendorId: string | undefined; moduleId: string | undefined }
    >({
      query: ({ vendorId, moduleId }) =>
        routes.anza.supplierDashboard.supplierModuleMetrics(vendorId, moduleId),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        {
          type: 'SupplierDashboardVendorModulesMetrics',
          id: 'SUPPLIER_DASHBOARD_VENDOR_MODULE_METRICS',
        },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
    // 👇 Query: Get Supplier's Module Dashboard Data
    getSupplierDashboardActiveProjects: builder.query<
      ActiveProjectsData[],
      { vendorId: string }
    >({
      query: ({ vendorId }) =>
        routes.anza.supplierDashboard.supplierProjects(vendorId),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'SupplierActiveProjects', id: 'SUPPLIER_ACTIVE_PROJECTS' },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: ActiveProjectsResponse) => response.data,
    }),
    // 👇 Query: Get All Supplier's Module Dashboard Data
    getSupplierDashboardAllActiveProjects: builder.query<
      ActiveProjectsData[],
      undefined
    >({
      query: () => routes.anza.supplierDashboard.allSuppliersProjects(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'AllSupplierActiveProjects', id: 'SUPPLIER_ACTIVE_PROJECTS' },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: ActiveProjectsResponse) => response.data,
    }),
  }),
});

export const {
  useGetDashboardPipelineQuery,
  useGetSupplierDashboardActiveProjectsQuery,
  useGetSupplierDashboardAllActiveProjectsQuery,
  useGetSupplierDashboardVendorModuleMetricsQuery,
} = supplierDashboardApi;
